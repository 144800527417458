import React, { useEffect, useState } from 'react'
import 'feather-icons'

import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllPairData } from '../contexts/PairData'
import PairList from '../components/PairList'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween, AutoRow } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import QuestionHelper from '../components/QuestionHelper'
import CheckBox from '../components/Checkbox'
import { AutoColumn } from '../components/Column'

function AllPairsPage() {
  const allPairs = useAllPairData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  const [useTracked, setUseTracked] = useState(true)

  return (
    <PageWrapper>
      <FullWrapper>
        <AutoColumn gap="24px" style={{ paddingBottom: below800 ? '0' : '24px' }}>
          {below800 ? (
            <TYPE.largeHeader>Top Pairs</TYPE.largeHeader>
          ) : (
            <>
              <TYPE.middleHeader>Top Pairs</TYPE.middleHeader>
              <Search />
            </>
          )}
          <AutoRow gap="4px">
            <CheckBox
              checked={useTracked}
              setChecked={() => setUseTracked(!useTracked)}
              text={'Hide untracked pairs'}
            />
            <QuestionHelper text="USD amounts may be inaccurate in low liquidity pairs or pairs without ETH or stablecoins." />
          </AutoRow>
        </AutoColumn>
        <Panel style={{ padding: below800 && '1rem 0 0 0 ' }}>
          <PairList pairs={allPairs} disbaleLinks={true} maxItems={50} useTracked={useTracked} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllPairsPage
