import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import 'feather-icons'
import styled from 'styled-components'
import Panel from '../components/Panel'
import {
  PageWrapper,
  ContentWrapperLarge,
  StyledIcon,
  BlockedWrapper,
  BlockedMessageWrapper,
} from '../components/index'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import Column, { AutoColumn } from '../components/Column'
import { ButtonLight, ButtonDark } from '../components/ButtonStyled'
import PairChart from '../components/PairChart'
import Link from '../components/Link'
import TxnList from '../components/TxnList'
import Loader from '../components/LocalLoader'
import { BasicLink, CustomLink } from '../components/Link'
import Search from '../components/Search'
import { formattedNum, formattedPercent, getPoolLink, getSwapLink, shortenAddress } from '../utils'
import { useColor } from '../hooks'
import { usePairData, usePairTransactions } from '../contexts/PairData'
import { TYPE, ThemedBackground } from '../Theme'
import { transparentize } from 'polished'
import CopyHelper from '../components/Copy'
import { useMedia } from 'react-use'
import DoubleTokenLogo from '../components/DoubleLogo'
import TokenLogo from '../components/TokenLogo'
import { Hover } from '../components'
import { useEthPrice } from '../contexts/GlobalData'
import Warning from '../components/Warning'
import { usePathDismissed, useSavedPairs } from '../contexts/LocalStorage'

import { Bookmark, PlusCircle, AlertCircle, ArrowRight } from 'react-feather'
import FormattedName from '../components/FormattedName'
import { useListedTokens } from '../contexts/Application'
import HoverText from '../components/HoverText'
import { UNTRACKED_COPY, PAIR_BLACKLIST, BLOCKED_WARNINGS } from '../constants'

const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 10px 20px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const TokenDetailsLayout = styled.div`
  display: inline-grid;
  width: 100%;
  grid-template-columns: auto auto auto auto 1fr;
  column-gap: 60px;
  align-items: start;

  &:last-child {
    align-items: center;
    justify-items: end;
  }

  @media screen and (max-width: 1440px) {
    column-gap: 30px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
      margin-bottom: 1rem;
    }

    &:last-child {
      align-items: start;
      justify-items: start;
    }
  }
`

const FixedPanel = styled(Panel)`
  padding: 0;
  gap: 10px 36px;
  width: fit-content;
  background: transparent;
  :hover {
    cursor: pointer;
  }
`

const HoverSpan = styled.span`
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const WarningIcon = styled(AlertCircle)`
  stroke: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  opacity: 0.6;
`

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function PairPage({ pairAddress, history }) {
  const {
    token0,
    token1,
    reserve0,
    reserve1,
    reserveUSD,
    trackedReserveUSD,
    oneDayVolumeUSD,
    volumeChangeUSD,
    oneDayVolumeUntracked,
    volumeChangeUntracked,
    liquidityChangeUSD,
  } = usePairData(pairAddress)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const transactions = usePairTransactions(pairAddress)
  const backgroundColor = useColor(pairAddress)

  const formattedLiquidity = reserveUSD ? formattedNum(reserveUSD, true) : formattedNum(trackedReserveUSD, true)
  const usingUntrackedLiquidity = !trackedReserveUSD && !!reserveUSD
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  // volume
  const volume = !!oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD, true) : formattedNum(oneDayVolumeUntracked, true)
  const usingUtVolume = oneDayVolumeUSD === 0 && !!oneDayVolumeUntracked
  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked)

  const showUSDWaning = usingUntrackedLiquidity | usingUtVolume

  // get fees	  // get fees
  const fees =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? usingUtVolume
        ? formattedNum(oneDayVolumeUntracked * 0.002, true)
        : formattedNum(oneDayVolumeUSD * 0.002, true)
      : '-'

  // token data for usd
  const [ethPrice] = useEthPrice()
  const token0USD =
    token0?.derivedETH && ethPrice ? formattedNum(parseFloat(token0.derivedETH) * parseFloat(ethPrice), true) : ''

  const token1USD =
    token1?.derivedETH && ethPrice ? formattedNum(parseFloat(token1.derivedETH) * parseFloat(ethPrice), true) : ''

  // rates
  const token0Rate = reserve0 && reserve1 ? formattedNum(reserve1 / reserve0) : '-'
  const token1Rate = reserve0 && reserve1 ? formattedNum(reserve0 / reserve1) : '-'

  // formatted symbols for overflow
  const formattedSymbol0 = token0?.symbol.length > 6 ? token0?.symbol.slice(0, 5) + '...' : token0?.symbol
  const formattedSymbol1 = token1?.symbol.length > 6 ? token1?.symbol.slice(0, 5) + '...' : token1?.symbol

  const below1080 = useMedia('(max-width: 1080px)')
  const below900 = useMedia('(max-width: 900px)')
  const below800 = useMedia('(max-width: 900px)')
  const below600 = useMedia('(max-width: 600px)')

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const [savedPairs, addPair] = useSavedPairs()

  const listedTokens = useListedTokens()

  if (PAIR_BLACKLIST.includes(pairAddress)) {
    return (
      <BlockedWrapper>
        <BlockedMessageWrapper>
          <AutoColumn gap="1rem" justify="center">
            <TYPE.light style={{ textAlign: 'center' }}>
              {BLOCKED_WARNINGS[pairAddress] ?? `This pair is not supported.`}
            </TYPE.light>
            <Link
              external={true}
              href={'https://andromeda-explorer.metis.io/address/' + pairAddress}
            >{`More about ${shortenAddress(pairAddress)}`}</Link>
          </AutoColumn>
        </BlockedMessageWrapper>
      </BlockedWrapper>
    )
  }

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />
      <span />
      {/* <Warning
        type={'pair'}
        show={!dismissed && listedTokens && !(listedTokens.includes(token0?.id) && listedTokens.includes(token1?.id))}
        setShow={markAsDismissed}
        address={pairAddress}
      /> */}
      <ContentWrapperLarge>
        <RowBetween>
          <TYPE.largeHeader
            fontSize={below1080 ? 48 : 24}
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            <BasicLink to="/pairs">{'Pairs '}</BasicLink>
            <ArrowRight width={below1080 ? 48 : 24} height={below1080 ? 48 : 24} style={{ margin: '0 10px' }} />
            <BasicLink style={{ width: 'fit-content' }} to={`/token/${token0?.id}`}>
              {token0?.symbol}
            </BasicLink>
            -
            <BasicLink style={{ width: 'fit-content' }} to={`/token/${token1?.id}`}>
              {token1?.symbol}
            </BasicLink>
          </TYPE.largeHeader>
          {!below800 && <Search small={true} />}
        </RowBetween>
        <DashboardWrapper>
          <RowBetween
            style={{
              marginBottom: below900 ? '0' : '2rem',
              flexWrap: 'wrap',
              gap: '26px',
            }}
          >
            <RowFixed
              style={{
                flexDirection: below1080 ? 'column' : 'initial',
                gap: '14px 36px',
                alignItems: below1080 ? 'flex-start' : 'center',
              }}
            >
              <FixedPanel onClick={() => history.push(`/token/${token0?.id}`)}>
                <RowFixed>
                  {token0 && <TokenLogo coinName={token0?.symbol} address={token0?.id} size={'16px'} />}
                  <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                    {token0 && token1
                      ? `1 ${formattedSymbol0} = ${token0Rate} ${formattedSymbol1} ${
                          parseFloat(token0?.derivedETH) ? '(' + token0USD + ')' : ''
                        }`
                      : '-'}
                  </TYPE.main>
                </RowFixed>
              </FixedPanel>
              <FixedPanel onClick={() => history.push(`/token/${token1?.id}`)}>
                <RowFixed>
                  {token1 && <TokenLogo coinName={token1?.symbol} address={token1?.id} size={'16px'} />}
                  <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                    {token0 && token1
                      ? `1 ${formattedSymbol1} = ${token1Rate} ${formattedSymbol0}  ${
                          parseFloat(token1?.derivedETH) ? '(' + token1USD + ')' : ''
                        }`
                      : '-'}
                  </TYPE.main>
                </RowFixed>
              </FixedPanel>
            </RowFixed>
            <RowFixed
              ml={below900 ? '0' : 'auto'}
              style={{
                flexDirection: below1080 ? 'row-reverse' : 'initial',
              }}
            >
              {!!!savedPairs[pairAddress] && !below1080 ? (
                <Hover onClick={() => addPair(pairAddress, token0.id, token1.id, token0.symbol, token1.symbol)}>
                  <StyledIcon>
                    <PlusCircle style={{ marginRight: '0.5rem' }} />
                  </StyledIcon>
                </Hover>
              ) : !below1080 ? (
                <StyledIcon>
                  <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                </StyledIcon>
              ) : (
                <></>
              )}

              <Link external href={getPoolLink(token0?.id, token1?.id)}>
                <ButtonDark>+ Add Liquidity</ButtonDark>
              </Link>
              <Link external href={getSwapLink(token0?.id, token1?.id)}>
                <ButtonDark ml={!below1080 && '.5rem'} mr={below1080 && '.5rem'}>
                  Trade
                </ButtonDark>
              </Link>
            </RowFixed>
          </RowBetween>
          <>
            {!below1080 && (
              <RowFixed>
                <TYPE.main fontSize={'1.125rem'} mr="6px">
                  Pair Stats
                </TYPE.main>
                {showUSDWaning ? (
                  <HoverText text={UNTRACKED_COPY}>
                    <WarningIcon />
                  </HoverText>
                ) : null}
              </RowFixed>
            )}
            <PanelWrapper style={{ marginTop: '1.5rem' }}>
              <Panel style={{ height: '100%' }}>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main>Total Liquidity </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.middleHeader fontSize={below1080 ? '24px' : '30px'} lineHeight={1} fontWeight={500}>
                      {formattedLiquidity}
                    </TYPE.middleHeader>
                    <TYPE.main>{liquidityChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }}>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main>Volume (24hrs) </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.middleHeader fontSize={below1080 ? '24px' : '30px'} lineHeight={1} fontWeight={500}>
                      {volume}
                    </TYPE.middleHeader>
                    <TYPE.main>{volumeChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }}>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main>Fees (24hrs)</TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.middleHeader fontSize={below1080 ? '24px' : '30px'} lineHeight={1} fontWeight={500}>
                      {fees}
                    </TYPE.middleHeader>
                    <TYPE.main>{volumeChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }}>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main>Pooled Tokens</TYPE.main>
                    <div />
                  </RowBetween>
                  <Hover onClick={() => history.push(`/token/${token0?.id}`)} fade={true}>
                    <AutoRow gap="4px">
                      {token0 && <TokenLogo coinName={token0?.symbol} address={token0?.id} />}
                      <TYPE.main fontSize={below1080 ? 18 : 20} lineHeight={1} fontWeight={600}>
                        <RowFixed>
                          {reserve0 ? formattedNum(reserve0) : ''}{' '}
                          <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} margin={true} />
                        </RowFixed>
                      </TYPE.main>
                    </AutoRow>
                  </Hover>
                  <Hover onClick={() => history.push(`/token/${token1?.id}`)} fade={true}>
                    <AutoRow gap="4px">
                      {token1 && <TokenLogo coinName={token1?.symbol} address={token1?.id} />}
                      <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                        <RowFixed>
                          {reserve1 ? formattedNum(reserve1) : ''}{' '}
                          <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} margin={true} />
                        </RowFixed>
                      </TYPE.main>
                    </AutoRow>
                  </Hover>
                </AutoColumn>
              </Panel>
              <Panel
                style={{
                  gridColumn: below1080 ? '1' : '2/4',
                  gridRow: below1080 ? '' : '1/5',
                  overflow: 'hidden',
                }}
              >
                <PairChart
                  address={pairAddress}
                  color={backgroundColor}
                  base0={reserve1 / reserve0}
                  base1={reserve0 / reserve1}
                />
              </Panel>
            </PanelWrapper>
            <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
              Transactions
            </TYPE.main>{' '}
            <Panel
              style={{
                marginTop: '1.5rem',
              }}
            >
              {transactions ? <TxnList transactions={transactions} /> : <Loader />}
            </Panel>
            <RowBetween style={{ marginTop: '3rem' }}>
              <TYPE.main fontSize={'1.125rem'}>Pair Information</TYPE.main>{' '}
            </RowBetween>
            <Panel
              rounded
              style={{
                marginTop: '1.5rem',
              }}
              p={20}
            >
              <TokenDetailsLayout>
                <Column style={{ alignSelf: 'stretch' }}>
                  <TYPE.main>Pair Name</TYPE.main>
                  <TYPE.main style={{ marginTop: 'auto' }}>
                    <RowFixed>
                      <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />
                      -
                      <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />
                    </RowFixed>
                  </TYPE.main>
                </Column>
                <Column>
                  <TYPE.main>Pair Address</TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      {pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={pairAddress} />
                  </AutoRow>
                </Column>
                <Column>
                  <TYPE.main>
                    <RowFixed>
                      <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />{' '}
                      <span style={{ marginLeft: '4px' }}>Address</span>
                    </RowFixed>
                  </TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      {token0 && token0.id.slice(0, 6) + '...' + token0.id.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={token0?.id} />
                  </AutoRow>
                </Column>
                <Column>
                  <TYPE.main>
                    <RowFixed>
                      <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />{' '}
                      <span style={{ marginLeft: '4px' }}>Address</span>
                    </RowFixed>
                  </TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={16}>
                      {token1 && token1.id.slice(0, 6) + '...' + token1.id.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={token1?.id} />
                  </AutoRow>
                </Column>
                <ButtonLight>
                  <Link href={'https://andromeda-explorer.metis.io/address/' + pairAddress} external>
                    View on Andromeda explorer ↗
                  </Link>
                </ButtonLight>
              </TokenDetailsLayout>
            </Panel>
          </>
        </DashboardWrapper>
      </ContentWrapperLarge>
    </PageWrapper>
  )
}

export default withRouter(PairPage)
