import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'

const TethysLogo = styled(Logo)`
  width: '100%';
  height: '100%';
`

const LogoLink = styled.a`
  color: ${({ theme }) => theme.logoColor};
`

const Box = styled.div`
  /* padding: 0px 20px; */
`

export const Header = () => {
  return (
    <Box>
      <LogoLink href="https://tethys.finance">
        <TethysLogo />
      </LogoLink>
    </Box>
  )
}
