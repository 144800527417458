import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { TYPE } from '../Theme'
import { AutoColumn } from '../components/Column'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
// import CheckBox from '../components/Checkbox'
// import QuestionHelper from '../components/QuestionHelper'

function AllTokensPage() {
  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  // const [useTracked, setUseTracked] = useState(true)

  return (
    <PageWrapper>
      <FullWrapper>
        <AutoColumn gap="24px" style={{ paddingBottom: below800 ? '0' : '24px' }}>
          {below800 ? (
            <TYPE.largeHeader>Top Tokens</TYPE.largeHeader>
          ) : (
            <>
              <TYPE.middleHeader>Top Tokens</TYPE.middleHeader>
              <Search />
            </>
          )}
        </AutoColumn>
        {/* <AutoRow gap="4px">
          <CheckBox checked={useTracked} setChecked={() => setUseTracked(!useTracked)} text={'Hide untracked tokens'} />
          <QuestionHelper text="USD amounts may be inaccurate in low liquidity pairs or pairs without ETH or stablecoins." />
        </AutoRow> */}
        <Panel
          style={{
            marginTop: '6px',
            padding: below800 && '1rem 0 0 0',
            boxShadow:
              '0px 100px 138px rgba(0, 0, 0, 0.1), 0px 58.88px 71.9808px rgba(0, 0, 0, 0.07232), 0px 30.24px 33.7824px rgba(0, 0, 0, 0.05616), 0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)',
          }}
        >
          <TopTokenList tokens={allTokens} itemMax={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllTokensPage
