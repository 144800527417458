import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import { Flex } from 'rebass'
import { Header } from './Header'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const backAnimation = keyframes`
 0% { transform: translateX(0) }
 50% { transform: translateX(-0.5rem) }
 100% { transform: translateX(0) }
`

const BackLink = styled.a`
  display: block;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;

  &:hover {
    animation-name: ${backAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`

export default function Title() {
  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'center' }}>
        <Header />
      </Flex>
    </TitleWrapper>
  )
}
