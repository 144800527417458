import tethysLogo from '../../assets/tethys-logo.svg'
import ethLogo from '../../assets/ether.png'

export const tethysList = [
  {
    chainId: 1088,
    address: '0x69fdb77064ec5c84FA2F21072973eB28441F43F3',
    decimals: 18,
    name: 'Tethys Token',
    symbol: 'TETHYS',
    logoURI: tethysLogo,
  },
  {
    chainId: 1088,
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    decimals: 18,
    name: 'Metis Token',
    symbol: 'METIS',
    logoURI: 'https://bridge.metis.io/static/media/metis.11931ddb.svg',
  },
  {
    chainId: 1088,
    address: '0xEA32A96608495e54156Ae48931A7c20f0dcc1a21',
    decimals: 6,
    name: 'USDC Token',
    symbol: 'm.USDC',
    logoURI: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/USDC/logo.png',
  },
  {
    chainId: 1088,
    address: '0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
    decimals: 6,
    name: 'USDT Token',
    symbol: 'm.USDT',
    logoURI: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/USDT/logo.png',
  },
  {
    chainId: 1088,
    address: '0x420000000000000000000000000000000000000A',
    decimals: 18,
    name: 'Ether',
    symbol: 'wETH',
    logoURI: ethLogo,
  },
  {
    chainId: 1088,
    address: '0x68D97B7A961a5239B9F911DA8dEb57F6eF6e5e28',
    decimals: 18,
    name: 'AAVE Token',
    symbol: 'AAVE',
    logoURI: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/AAVE/logo.png',
  },
  {
    chainId: 1088,
    address: '0x87DD4a7Ad23B95cD9fF9C26B5cF325905CaF8663',
    decimals: 18,
    name: 'Curve DAO Token',
    symbol: 'CRV',
    logoURI: 'https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/CRV/logo.png',
  },
  {
    chainId: 1088,
    address: '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
    decimals: 18,
    name: 'MAI Token',
    symbol: 'MAI',
    logoURI: 'https://assets.coingecko.com/coins/images/15264/small/mimatic-red.png',
  },
  {
    chainId: 1088,
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    decimals: 18,
    name: 'Qi Token',
    symbol: 'Qi',
    logoURI: 'https://assets.coingecko.com/coins/images/15329/small/qi.png',
  },
  {
    chainId: 1088,
    address: '0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
    logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png',
  },
  {
    chainId: 1088,
    address: '0x72c232D56542Ba082592DEE7C77b1C6CFA758BCD',
    decimals: 9,
    name: 'Maia',
    symbol: 'MAIA',
    logoURI: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b1ddf964-49db-45f0-4b66-ae8a5a864400/public',
  },
  {
    chainId: 1088,
    address: '0x88C37E0bc6a237e96bc4A82774A38BBc30efF3Cf',
    decimals: 18,
    name: 'Hades',
    symbol: 'HADES',
    logoURI: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/3a8a6e3b-a664-4e2d-2399-c9212b2af000/public',
  },
  {
    chainId: 1088,
    address: '0xEfB15eF34f85632fd1D4C17FC130CcEe3D3D48aE',
    decimals: 18,
    name: 'Hellshare',
    symbol: 'HELLSHARE',
    logoURI: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/70d7885d-da7d-40df-a826-e13c55021a00/public',
  },
  {
    chainId: 1088,
    address: '0xb26F58f0b301a077cFA779c0B0f8281C7f936Ac0',
    decimals: 18,
    name: 'Starstream',
    symbol: 'STAR',
    logoURI: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/60d98cd1-0dd9-49e3-a962-2e722c835f00/public',
  },
  {
    chainId: 1088,
    address: '0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4',
    decimals: 8,
    name: 'Relay Wrapped BTC',
    symbol: 'wBTC',
    logoURI: 'https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579c',
  },
  {
    chainId: 1088,
    address: '0x4651B38e7ec14BB3db731369BFE5B08F2466Bd0A',
    decimals: 18,
    name: 'Relay DAI Token',
    symbol: 'DAI',
    logoURI: 'https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734',
  },
  {
    chainId: 1088,
    address: '0xE253E0CeA0CDD43d9628567d097052B33F98D611',
    decimals: 18,
    name: 'Relay Avalanche Token',
    symbol: 'AVAX',
    logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png',
  },
  {
    chainId: 1088,
    address: '0xa9109271abcf0C4106Ab7366B4eDB34405947eED',
    decimals: 18,
    name: 'Relay Fantom Token',
    symbol: 'FTM',
    logoURI: 'https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016',
  },
  {
    chainId: 1088,
    address: '0x66673f0a3b5d99524bA76A558B93A9ca1386f4Cd',
    decimals: 18,
    name: 'XCAD Network',
    symbol: 'XCAD',
    logoURI: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/71ebab3f-43a8-4899-1171-1c9c109bbb00/public',
  },
]
