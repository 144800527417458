import React from 'react'

const Logo = ({ ...props }) => {
  return (
    <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg width="100%" height="100%" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M72.9602 145.92C113.255 145.92 145.92 113.255 145.92 72.9602C145.92 32.6654 113.255 0 72.9602 0C32.6654 0 0 32.6654 0 72.9602C0 113.255 32.6654 145.92 72.9602 145.92Z"
          fill="#CCB175"
        />
        <path
          d="M118.678 84.9629L125.152 81.7262C126.231 81.1868 126.5 79.7033 125.691 78.7593L117.599 58.9346C116.386 55.9676 115.711 53.0007 115.442 49.764C113.284 22.5219 90.3574 1.21375 62.441 1.61834C34.3898 2.02292 11.4633 24.8146 10.9238 53.0007C10.5192 77.9501 27.5118 98.9885 50.5732 104.653L47.8759 106.136L30.2091 145.786H78.3547L90.2225 119.757C91.5712 116.79 94.673 115.037 97.9097 115.172L108.833 116.116C112.475 116.386 115.442 113.554 115.307 109.912L115.172 106.406C115.172 103.978 115.846 101.686 117.195 99.6628V98.9885C117.195 97.6399 116.116 96.4262 114.767 96.1564C110.047 95.3473 107.35 93.0546 107.35 93.0546C110.587 93.0546 114.498 92.65 117.06 92.2454C118.544 92.1106 119.622 90.6271 119.353 89.1436L118.678 84.9629Z"
          fill="white"
        />
        <path
          d="M64.1942 106.136L84.9629 62.7107C100.337 60.2832 109.912 30.4788 108.968 28.7255C106.406 23.8705 104.113 21.3082 101.686 18.6109C91.8409 7.41739 78.085 1.34861 62.5759 1.61834C34.12 1.88806 10.9238 25.354 10.9238 53.9447C10.9238 68.1052 16.588 81.0519 25.7586 90.3574C11.0587 91.4363 -0.404574 103.709 1.16825e-05 118.678C0.404597 133.378 12.2724 145.381 26.9724 145.786C38.3008 146.055 48.1457 139.717 52.731 130.276C52.731 130.142 52.8658 130.007 52.8658 129.872L64.1942 106.136Z"
          fill="#0000AE"
        />
        <path
          d="M79.029 14.9697C75.7923 27.7815 69.0492 39.7842 60.0135 49.4943L78.085 40.4585C69.9933 58.3951 56.9117 72.4208 40.998 77.6804L56.7768 77.1409C44.3695 89.6831 27.7815 93.0546 12.4073 87.6601C12.4073 87.6601 12.2724 87.6601 12.2724 87.5253C6.33851 85.3675 5.25962 76.4666 10.2495 72.2859L79.029 14.9697Z"
          fill="white"
        />
        <path
          d="M107.89 67.1612C109.677 67.1612 111.126 64.746 111.126 61.7667C111.126 58.7874 109.677 56.3722 107.89 56.3722C106.102 56.3722 104.653 58.7874 104.653 61.7667C104.653 64.746 106.102 67.1612 107.89 67.1612Z"
          fill="#0000AE"
        />
      </svg>
      <mask
        id="mask0_2_1592"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        <rect width="100%" height="100%" rx="8" fill="#76BCA3" />
      </mask>
    </svg>
  )
}

export default Logo
